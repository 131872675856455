import * as moment from 'moment';
import { environment } from '../../environments/environment';

export class B2CRedirectErrorCodes {
    public static get PasswordReset(): string {
        return 'AADB2C90118';
    }
    public static get Cancel(): string {
        return 'AADB2C90118';
    }
}

export class B2CPolicyFlows {
    public static get B2C_CLIENT_ID(): string {
        return 'e6797b67-8018-4d9e-8865-66ed666bb9b4';
    }
    public static get B2C_SCOPES(): string[] {
        return [`https://deadad.onmicrosoft.com/${this.B2C_CLIENT_ID}/dead`];
    }
    public static get B2C_SCOPES_EXTRA(): string[] {
        return ['openid', 'profile', 'offline_access'];
    }
    public static get AUTHORITY_URL(): string {
        return 'https://deadAD.b2clogin.com/deadad.onmicrosoft.com';
    }
    public static get B2C_SIGNIN_FLOW(): string {
        return 'B2C_1A_signup_signinV2';
    }
    public static get B2C_SIGNUP_FLOW(): string {
        return 'B2C_1A_signupV2'
    }
    public static get B2C_PASSWORD_RESET_FLOW(): string {
        return 'B2C_1A_PasswordReset'
    }
    public static get B2C_PROFILE_EDIT_FLOW(): string {
        return 'B2C_1A_ProfileEditV2'
    }
    public static get B2C_REDIRE_URL(): string {
        return `${environment.baseUrl}/home`;
    }
}

export const States = [
    {
        name: 'Alabama',
        abbreviation: 'AL'
    },
    {
        name: 'Alaska',
        abbreviation: 'AK'
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS'
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ'
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR'
    },
    {
        name: 'California',
        abbreviation: 'CA'
    },
    {
        name: 'Colorado',
        abbreviation: 'CO'
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT'
    },
    {
        name: 'Delaware',
        abbreviation: 'DE'
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC'
    },
    {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM'
    },
    {
        name: 'Florida',
        abbreviation: 'FL'
    },
    {
        name: 'Georgia',
        abbreviation: 'GA'
    },
    {
        name: 'Guam',
        abbreviation: 'GU'
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI'
    },
    {
        name: 'Idaho',
        abbreviation: 'ID'
    },
    {
        name: 'Illinois',
        abbreviation: 'IL'
    },
    {
        name: 'Indiana',
        abbreviation: 'IN'
    },
    {
        name: 'Iowa',
        abbreviation: 'IA'
    },
    {
        name: 'Kansas',
        abbreviation: 'KS'
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY'
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA'
    },
    {
        name: 'Maine',
        abbreviation: 'ME'
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH'
    },
    {
        name: 'Maryland',
        abbreviation: 'MD'
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA'
    },
    {
        name: 'Michigan',
        abbreviation: 'MI'
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN'
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS'
    },
    {
        name: 'Missouri',
        abbreviation: 'MO'
    },
    {
        name: 'Montana',
        abbreviation: 'MT'
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE'
    },
    {
        name: 'Nevada',
        abbreviation: 'NV'
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH'
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ'
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM'
    },
    {
        name: 'New York',
        abbreviation: 'NY'
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC'
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND'
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP'
    },
    {
        name: 'Ohio',
        abbreviation: 'OH'
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK'
    },
    {
        name: 'Oregon',
        abbreviation: 'OR'
    },
    {
        name: 'Palau',
        abbreviation: 'PW'
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA'
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR'
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI'
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC'
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD'
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN'
    },
    {
        name: 'Texas',
        abbreviation: 'TX'
    },
    {
        name: 'Utah',
        abbreviation: 'UT'
    },
    {
        name: 'Vermont',
        abbreviation: 'VT'
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI'
    },
    {
        name: 'Virginia',
        abbreviation: 'VA'
    },
    {
        name: 'Washington',
        abbreviation: 'WA'
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV'
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI'
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY'
    }
];

export const compareDate = (date1: Date, date2: Date): number => {

    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const m1 = moment(`${d1.getMonth()+1}-${d1.getDate()}-${d1.getFullYear()}`, 'MM-DD-YYYY')
    const m2 = moment(`${d2.getMonth()+1}-${d2.getDate()}-${d2.getFullYear()}`, 'MM-DD-YYYY')


    if (m1.isSameOrAfter(m2))
        return 1;
    return -1;
}
