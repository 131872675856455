import { Component } from '@angular/core';

@Component({
  selector: 'dead-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent {

  constructor() { }

}
