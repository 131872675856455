<div class="container position-relative z-index-2">
    <div class="row bg-gray-100 shadow-lg rounded mt-n9">
        <div class="col-md-{{12/subscriptionTypes.length}} pr-0 pl-0"
            [ngClass]="{'pr-2': first, 'pl-2': last, 'pr-2 pl-2': !first && !last}"
            *ngFor="let subscription of subscriptionTypes; let first = first; let last = last;">
            <div class="card border-0">
                <!-- Header -->
                <header class="card-header text-center p-1">
                    <h4 class="h4">{{subscription.name}}</h4>
                    <!-- <p class="text-secondary">{{subscription.description}}</p> -->
                    <span class="d-block">
                        <span class="display-4 text-dark"> {{subscription.price | currency}} </span>
                        <span class="d-block text-secondary font-size-14">One Time</span>
                    </span>
                </header>
                <!-- End Header -->
                <!-- Content -->
                <!-- <div class="card-body p-4" style="height: 200px;">
                    <ul class="list-unstyled mb-4">
                        <li class="font-size-14 py-2" *ngFor="let feature of subscription.features">
                            {{feature}}
                        </li>
                    </ul>
                </div> -->
                <button type="button" (click)="purchase(subscription.code)" class="btn btn-block btn-primary">
                    Purchase</button>
                <!-- End Content -->
            </div>
        </div>
    </div>

    <div class="mt-7 text-center w-100 d-flex justify-content-center align-items-center">
        <div class="bulk-purchase">
            <div class="d-flex justify-content-center align-items-center">
                <button type="button" (click)="purchase('six_months_bulk')" class="btn-bulk-purchase">
                    Purchase bulk codes for a Class or School
                </button>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
                <label class="btn-bulk-description px-2">
                    Bulk codes to the <span class="highlight-bold">DEAD!</span> testing simulator may be purchased for <span class="highlight-bold">$49.95 each</span>,  for 6 months of access. Minimum of 5 codes per order.
                </label>
            </div>
        </div>
    </div>
</div>
