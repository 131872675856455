import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstValueFrom, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { addEditDeleteSchoolComplete, addEditDeleteSchoolFailure, addSchool, deleteSchool, editSchool, loadSchools, loadSchoolsFailure, loadSchoolsSuccess, reloadSchools } from '../actions/school.actions';
import { SchoolService } from '../core/services/school.service';
import { ToastService } from '../core/services/toast.service';



@Injectable()
export class SchoolEffects {

    constructor(
        private actions$: Actions,
        private schoolsService: SchoolService,
        private toastService: ToastService,
        private router: Router
    ) { }

    loadSchools$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSchools),
                exhaustMap(() =>
                    this.schoolsService.getAll()
                        .pipe(
                            map((schools) => {
                                return loadSchoolsSuccess({ schools });
                            }),
                            catchError((error) => {
                                return of(loadSchoolsFailure({ error }));
                            })
                        )
                )
            )
    );

    reloadSchools$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(reloadSchools),
                exhaustMap(async () => {
                    try {
                        await firstValueFrom(this.schoolsService.deleteCache());
                    } catch (error) {
                        
                    }
                    
                    return await firstValueFrom(this.schoolsService.getAll())
                        .then(schools => loadSchoolsSuccess({ schools }))
                        .catch((error) => loadSchoolsFailure({ error }));
                })
            )    
    );

    addSchool$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addSchool),
                exhaustMap(data =>
                    this.schoolsService.add(data.school)
                        .pipe(
                            map(() => {
                                return addEditDeleteSchoolComplete({ redirectUrl: data.redirectUrl });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Adding School', 'Add School Failed!');
                                return of(addEditDeleteSchoolFailure({ error }));
                            })
                        )
                )
            )
    );

    editSchool$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editSchool),
                exhaustMap(data =>
                    this.schoolsService.edit(data.school)
                        .pipe(
                            map(() => {
                                return addEditDeleteSchoolComplete({ redirectUrl: data.redirectUrl });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Editing School', 'Edit School Failed!');
                                return of(addEditDeleteSchoolFailure({ error }));
                            })
                        )
                )
            )
    );

    deleteSchool$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteSchool),
                map(action => action.schoolId),
                exhaustMap(schoolId =>
                    this.schoolsService.delete(schoolId)
                        .pipe(
                            map(() => {
                                return addEditDeleteSchoolComplete({ redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Deleting School', 'Delete School Failed!');
                                return of(addEditDeleteSchoolFailure({ error }));
                            })
                        )
                )
            )
    );

    addEditDeleteSchool$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addEditDeleteSchoolComplete),
                map(action => {
                    if (action.redirectUrl) {
                        this.router.navigate([action.redirectUrl]);
                    }
                    return reloadSchools();
                })
            )
    );

}
