import {
    createFeatureSelector,
    createReducer,
    createSelector,
    on
} from '@ngrx/store';
import { School } from 'src/app/core/models/school.model';
import { loadSchools, loadSchoolsSuccess, reloadSchools } from '../actions/school.actions';

export const schoolFeatureKey = 'school';

export interface SchoolState {
    schools: School[],
    schoolLoading: boolean
}

const initialState: SchoolState = {
    schools: [],
    schoolLoading: false
}

export const reducers = createReducer(
    initialState,
    on(loadSchools, reloadSchools, (state) => ({ ...state, schools: [], schoolLoading: true })),
    on(loadSchoolsSuccess, (state, { schools }) => ({ ...state, schools, schoolLoading: false })),
);



// selectors
export const getSchoolState = createFeatureSelector<SchoolState>(schoolFeatureKey);

export const getSchoolsFromState = createSelector(getSchoolState, (state): School[] => state.schools);
export const isSchoolsLoading = createSelector(getSchoolState, (state): boolean => state.schoolLoading);
