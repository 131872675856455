import {
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { loadEmailTemplates, reloadEmailTemplates, loadEmailTemplatesSuccess, sendBulkEmailRequest, sendBulkEmailRequestComplete, sendBulkEmailRequestFailure, sendContactUsRequestComplete, sendContactUsRequestFailure, sendContactUsRequest, addEmailTemplate, editEmailTemplate, deleteEmailTemplate, addEditDeleteEmailTemplateComplete, addEditDeleteEmailTemplateFailure } from '../actions/email.actions';
import { EmailTemplate } from '../core/models/email-templates.model';

export const emailFeatureKey = 'email';

export interface EmailState {
    emailTemplates: EmailTemplate[],
    emailTemplatesLoading: boolean,
    sendBulkEmailStarted: boolean,
    sendBulkEmailComplete: boolean,
    sendBulkEmailFailure: boolean,
    sendContactUsStarted: boolean,
    sendContactUsComplete: boolean,
    addEditDeleteEmailTemplateInProgress: boolean,
}

const initialState: EmailState = {
    emailTemplates: [],
    emailTemplatesLoading: false,
    sendBulkEmailStarted: false,
    sendBulkEmailComplete: false,
    sendContactUsStarted: false,
    sendContactUsComplete: false,
    sendBulkEmailFailure: false,
    addEditDeleteEmailTemplateInProgress: false,
}

export const reducers = createReducer(
    initialState,
    on(loadEmailTemplates, reloadEmailTemplates, (state) => ({ ...state, emailTemplates: [], emailTemplatesLoading: true })),
    on(loadEmailTemplatesSuccess, (state, { emailTemplates }) => ({ ...state, emailTemplates, emailTemplatesLoading: false })),
    on(sendBulkEmailRequest, (state) => ({ ...state, sendBulkEmailStarted: true, sendBulkEmailComplete: false, sendBulkEmailFailure: false })),
    on(sendBulkEmailRequestComplete, (state) => ({ ...state, sendBulkEmailStarted: false, sendBulkEmailComplete: true, sendBulkEmailFailure: false })),
    on(sendBulkEmailRequestFailure, (state) => ({ ...state, sendBulkEmailStarted: false, sendBulkEmailComplete: false, sendBulkEmailFailure: true })),
    on(sendContactUsRequest, (state) => ({ ...state, sendContactUsStarted: true, sendContactUsComplete: false })),
    on(sendContactUsRequestComplete, (state) => ({ ...state, sendContactUsStarted: false, sendContactUsComplete: true })),
    on(sendContactUsRequestFailure, (state) => ({ ...state, sendContactUsStarted: false, sendContactUsComplete: false })),
    on(addEmailTemplate, editEmailTemplate, deleteEmailTemplate, (state) => ({ ...state, addEditDeleteEmailTemplateInProgress: true })),
    on(addEditDeleteEmailTemplateComplete, (state) => ({ ...state, emailTemplates: [], addEditDeleteEmailTemplateInProgress: false })),
    on(addEditDeleteEmailTemplateFailure, (state) => ({ ...state, addEditDeleteEmailTemplateInProgress: false })),
);

export const metaReducers: MetaReducer<EmailState>[] = !environment.production ? [] : [];

export const getEmailState = createFeatureSelector<EmailState>(emailFeatureKey);
export const getEmailTemplatesFromState = createSelector(getEmailState, (state): EmailTemplate[] => state.emailTemplates);
export const isAddEditDeleteEmailTemplateInProgress = createSelector(getEmailState, (state): boolean => state.addEditDeleteEmailTemplateInProgress);
