import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from '../reducers/app.reducers';
import { selectRouteData } from '../reducers/routing.reducers';


@Injectable()
export class RoutingEffects {
    title = 'D.E.A.D.';
    pageNameBase = 'D.E.A.D.';

    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private titleService: Title) { }

    updateTitle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction),
                concatMap((action) =>
                    of(action).pipe(
                        withLatestFrom(this.store.select(selectRouteData))
                    )
                ),
                map(([, routeData]) => {
                    window.scrollTo(0, 0)
                    if (routeData.title && !routeData.event) {
                        return `${this.pageNameBase} - ${routeData.title}`;
                    }
                    if (routeData.event) {
                        return `${this.pageNameBase} - ${routeData.title}: ${routeData.event.shortName}`;
                    }
                }),
                tap((title) => this.titleService.setTitle(title))
            ),
        {
            dispatch: false,
        }
    );
}
