import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';

export const signupStarted = createAction(
    '[Msal] Signup Started',
    props<{ redirectUrl: string }>()
);

export const signupResponseRecieved = createAction(
    '[Msal] Signup Response Recieved',
    props<{ resp: AuthenticationResult }>()
);

export const signupSuccess = createAction(
    '[Msal] Signup Success',
    props<{ isAdmin: boolean, isInstructor: boolean, isStudent: boolean, schoolId: string, subExirationDate: Date, resp: AuthenticationResult }>()
);

export const signupComplete = createAction('[Msal] Signup Complete');

export const signupFailure = createAction(
    '[Msal] Signup Failure',
    props<{ error: AuthError }>()
);

