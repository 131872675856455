import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ToastService } from '../services/toast.service';
import { DEADMsalService } from 'src/app/msal/services/dead-msal.service';
import { UserService } from '../services/user.service';
import { QuestionCategoryService } from '../services/question-category.service';
import { QuestionService } from '../services/question.service';
import { compareDate } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class StudentGuardService {
    constructor(
        private deadMsalService: DEADMsalService,
        private router: Router,
        private userService: UserService,
        private questionCategoryService: QuestionCategoryService,
        private questionService: QuestionService,
        private toastService: ToastService,
    ) { }

    canActivate(): Promise<boolean> | UrlTree | boolean {
        const now = new Date();
        const subEndDate = this.deadMsalService.getSubExirationDate();
        if (this.deadMsalService.isStudent() && compareDate(subEndDate, now) === 1) {
            const url = window.location.href;
            if (url.includes('admin') || url.includes('instructor')) {
                return Promise.all([
                    this.userService.clearUserCache().toPromise(),
                    this.questionCategoryService.deleteCache().toPromise(),
                    this.questionService.deleteCache().toPromise(),
                ]).then(() => true);
            }
            return true;
        }
        this.toastService.error('Please renew your subscription to proceed.', 'Your Subscription Has Expired');
        return this.router.parseUrl('/home');
    }
}
