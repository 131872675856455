import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PurchaseSubscription } from '../../shared/purchaseSubscription';
import { DeadSubscription } from '../models/subscription.model';
import { VoucherCode } from '../models/voucher-code.model';
import { HttpUtilService } from '../../shared/http-util.service';
import { DbService } from './db.service';
import {PurchaseVouchers} from "../../shared/purchaseVouchers";
@Injectable({
    providedIn: 'root'
})
export class SubscriptionsService {
    private tableTable = 'deadSubscriptions'
    constructor(private http: HttpClient, private httpUtils: HttpUtilService, private dbService: DbService) { }

    getSubscriptionTypes(showDeleted: boolean = false): Observable<DeadSubscription[]> {
        let url = `${environment.GENERAL_API_BASE}subscription-types`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.dbService.getAll(this.tableTable, url);
    }
    purchaseSubscription(purchaseSubscription: PurchaseSubscription): Observable<boolean | object> {
        const url = `${environment.GENERAL_API_BASE}subscriptions/purchase`;
        return this.http.post(url, purchaseSubscription);
    }

    purchaseVouchers(purchaseVouchers: PurchaseVouchers): Observable<boolean | object> {
        const url = `${environment.GENERAL_API_BASE}vouchers/purchase`;
        return this.http.post(url, purchaseVouchers);
    }
    getPurchasedSubscriptions(): Observable<any> {
        const url = `${environment.ADMIN_API_BASE}subscriptions/purchase`;
        return this.http.get(url);
    }
    getPurchasedSubscriptionsForUser(): Observable<any> {
        const url = `${environment.STUDENT_API_BASE}subscriptions/purchase`;
        return this.http.get(url);
    }
    getPurchasedSubscriptionsByUserId(userId: string): Observable<PurchaseSubscription[]> {

        const url = `${environment.ADMIN_API_BASE}subscriptions/purchase/${userId}`;

        return this.http.get<PurchaseSubscription[]>(url);
    }
    applyVoucherCode(voucherCode, subscriptionType): Observable<VoucherCode> {
        let url = `${environment.GENERAL_API_BASE}subscriptions/voucher-code/apply?voucherCode=${voucherCode}`;
        if (subscriptionType) {
            url += `&subscriptionType=${subscriptionType}`;
        }

        return this.http.get(url).pipe(map((p: VoucherCode) => p));
    }

    getUserActiveSubscription(userId: string): Observable<PurchaseSubscription> {
        return this.http.get(`${environment.ADMIN_API_BASE}subscriptions/user/active/${userId}`)
            .pipe(map((p: PurchaseSubscription) => p));
    }
    deletePurchaseHistory(ids: string[]): Observable<any> {
        return this.httpUtils.bulkDelete(ids, 'ids', `${environment.ADMIN_API_BASE}subscriptions/purchases/delete`);
    }
    setPaidBulkPurchases(ids: string[]): Observable<any> {
        return this.http.post(`${environment.ADMIN_API_BASE}subscriptions/purchases/bulk/paid`, ids)
    }
    updatePurchaseHistory(purchaseId: string, userEmail: string, sendEmail: boolean): Observable<any> {
        return this.http
            .post(`${environment.ADMIN_API_BASE}subscriptions/update`,
                {
                    id: purchaseId,
                    userEmail,
                    sendEmail
                });

    }
}
