import { EmailTemplate } from './../models/email-templates.model';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ContactUs } from '../models/contact-us.model';
import { environment } from 'src/environments/environment';
import { GenericEmail } from '../models/generic-email.model';
import { isUndefined, isNull } from 'lodash';
import { DbService } from './db.service';
import { iif } from 'rxjs/internal/observable/iif';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    private emailTemplateApiBase = 'email/templates/';
    private tableName = 'emailTemplates'

    constructor(private http: HttpClient, private dbService: DbService) { }

    deleteTemplateCache(): Observable<void> {
        return this.dbService.clearTableData<EmailTemplate>(this.tableName);
    }
    sumbitContactRequest(contactRequest: ContactUs): Observable<any> {
        return this.http.post(
            `${environment.GENERAL_API_BASE}ContactUs`,
            contactRequest
        );
    }
    sendBulkEmail(bulkEmail: GenericEmail): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}email/create/bulk`,
            bulkEmail
        );
    }
    getEmailTemplates(showDeleted: boolean = false, skipHttp: boolean = false): Observable<EmailTemplate[]> {
        let url = `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.dbService.getAll<EmailTemplate>(this.tableName, skipHttp ? '' : url);
    }
    getEmailTemplate(emailTemplateId: string, skipHttp: boolean = false): Observable<EmailTemplate> {
        return this.dbService.getFilter<EmailTemplate>(
            this.tableName, 'id', emailTemplateId, skipHttp ? '' : `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}get/${emailTemplateId}`);
    }
    codeExists(code: string, id: string = ''): Observable<boolean> {
        let url = `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}CodeExists/${code}`;
        if (id) {
            url += `/${id}`;
        }

        return this.http.get(url).pipe(map((i: boolean) => i));
    }

    automationJobCodeExists(automationJobCode: string, id: string = ''): Observable<boolean> {
        let url = `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}automationJobExists/${automationJobCode}`;
        if (id) {
            url += `/${id}`;
        }

        return this.http.get(url).pipe(map((i: boolean) => i));
    }

    addEmailTemplate(newQuestionCategory: EmailTemplate): Observable<any> {
        return this.http
            .post(
                `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}Add`,
                newQuestionCategory
            );
    }

    editEmailTemplate(editQuestionCategory: EmailTemplate): Observable<any> {
        return this.http
            .post(
                `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}Edit`,
                editQuestionCategory
            );
    }

    deleteEmailTemplate(templateId: string): Observable<any> {
        return this.http
            .delete(
                `${environment.ADMIN_API_BASE}${this.emailTemplateApiBase}Delete/${templateId}`
            );
    }
}
