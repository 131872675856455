import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'dead-logout-redirect',
    templateUrl: './logout-redirect.component.html',
    styleUrls: ['./logout-redirect.component.scss']
})
export class LogoutRedirectComponent implements OnInit {

    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
        sessionStorage.clear();
        this.router.navigate(['home']);
    }

}
