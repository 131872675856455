<div class="container pt-3">
    <h3>Please login or create an account before proceeding</h3>
    <div class="form-row">
        <div class="form-group col-md-12">
            <button type="button"
                class="btn btn-primary mr-2"
                (click)="login()">Login</button>
            <button type="button"
                class="btn btn-secondary"
                (click)="signup()">Create Account</button>
        </div>
    </div>
    <div class="card card-frame mb-1" *ngIf="isActivatingSub">
        <div class="card-body">
            To get started and activate your student account, we first need you to create an account or login if you already have one.
        </div>
    </div>
    <div class="card card-frame mb-1" *ngIf="isPurchase">
        <div class="card-body">
            To get started we first need you to create an account or login if you already have one.
        </div>
    </div>
</div>
