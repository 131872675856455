<!-- ========== FOOTER ========== -->
<footer class="bg-dark">
    <div class="container space-2">
        <div class="row justify-content-md-between">
            <div class="col-6 col-md-6 col-lg-2 order-lg-4 mb-7 mb-lg-0">
                <h3 class="h6 text-white mb-3">Company</h3>
                <!-- List Group -->
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        [routerLink]="'/contact-us'">Contact Us</a>
                </div>
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        [routerLink]="'/frequently-asked-questions'">Help</a>
                </div>
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        [routerLink]="'/terms'">Terms & Conditions</a>
                </div>
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        [routerLink]="'/privacy'">Privacy Policy</a>
                </div>
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        [routerLink]="'/fulfillment'">Fulfillment Policy</a>
                </div>
                <!-- End List Group -->
            </div>
            <div class="col-6 col-md-6 col-lg-2 order-lg-4 mb-7 mb-lg-0">
                <h3 class="h6 text-white mb-3">Social</h3>
                <!-- List -->
                <div class="list-group list-group-flush list-group-transparent">
                    <a class="list-group-item list-group-item-action"
                        href="https://www.facebook.com/DEADDrills"
                        target="_blank">
                        <fa-icon class="min-width-3 text-center mr-2"
                            [icon]="faFacebookF"></fa-icon> Facebook
                    </a>
                </div>
                <!-- End List -->
            </div>
            <div class="col-lg-4 order-lg-1 d-flex align-items-start flex-column">
                <!-- Logo -->
                <a class="d-inline-block mb-5"
                    routerLink="/home"
                    aria-label="dead">
                    <object class="img-fluid"
                        type="image/svg+xml"
                        data="../../assets/svg/logos/dead_footer_logo.svg"> D.E.A.D.
                        <!-- fallback image in CSS -->
                    </object>
                </a>
                <!-- End Logo -->
            </div>
        </div>
        <div class="row justify-content-md-between">
            <div class="col-12 m-0">
                <p class="small text-muted">All rights reserved. &copy; D.E.A.D. {{todayYear}}, An Anubis Publications, Inc. Product
                </p>
            </div>
        </div>
    </div>
</footer>
<!-- ========== END FOOTER ========== -->
