<div class="container space-2 space-2--lg">
    <div class="row">
        <div id="stickyBlockStartPoint" class="col-sm-4 col-lg-3 mb-7 mb-sm-0">
            <!-- Nav -->
            <nav class="js-sticky-block" data-has-sticky-header="true" data-offset-target="#logoAndNav"
                data-sticky-view="sm" data-start-point="#stickyBlockStartPoint" data-end-point="#stickyBlockEndPoint"
                data-offset-top="20" data-offset-bottom="20">
                <ul id="scrollNav" class="list-group list-group-flush list-group-borderless">
                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('intro')">
                            <span class="d-inline-block min-width-3 mr-2">1.0</span>
                            <span class="media-body">
                                Introduction
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('ip')">
                            <span class="d-inline-block min-width-3 mr-2">2.0</span>
                            <span class="media-body">
                                Intellectual Property
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('age-restrictions')">
                            <span class="d-inline-block min-width-3 mr-2">3.0</span>
                            <span class="media-body">
                                Age Restrictions
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('accounts')">
                            <span class="d-inline-block min-width-3 mr-2">4.0</span>
                            <span class="media-body">
                                Accounts
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('goods')">
                            <span class="d-inline-block min-width-3 mr-2">5.0</span>
                            <span class="media-body">
                                Sale of Goods And Services
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('third-goods')">
                            <span class="d-inline-block min-width-3 mr-2">6.0</span>
                            <span class="media-body">
                                Third Party Goods and Services
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('subscriptions')">
                            <span class="d-inline-block min-width-3 mr-2">7.0</span>
                            <span class="media-body">
                                Subscriptions
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('payments')">
                            <span class="d-inline-block min-width-3 mr-2">8.0</span>
                            <span class="media-body">
                                Payments
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('shipping')">
                            <span class="d-inline-block min-width-3 mr-2">9.0</span>
                            <span class="media-body">
                                Shipping and Delivery
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('consumer-protection-law')">
                            <span class="d-inline-block min-width-3 mr-2">10.0</span>
                            <span class="media-body">
                                Consumer Protection Law
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('links')">
                            <span class="d-inline-block min-width-3 mr-2">11.0</span>
                            <span class="media-body">
                                Links to Other Websites
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('liability')">
                            <span class="d-inline-block min-width-3 mr-2">12.0</span>
                            <span class="media-body">
                                Limitation of Liability
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('indemnity')">
                            <span class="d-inline-block min-width-3 mr-2">13.0</span>
                            <span class="media-body">
                                Indemnity
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('applicable-law')">
                            <span class="d-inline-block min-width-3 mr-2">14.0</span>
                            <span class="media-body">
                                Applicable Law
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('severability')">
                            <span class="d-inline-block min-width-3 mr-2">15.0</span>
                            <span class="media-body">
                                Severability
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('changes')">
                            <span class="d-inline-block min-width-3 mr-2">16.0</span>
                            <span class="media-body">
                                Changes
                            </span>
                        </button>
                    </li>

                    <li>
                        <button class="list-group-item list-group-item-action media d-flex btn btn-link"
                            (click)="scrollTo('contact-details')">
                            <span class="d-inline-block min-width-3 mr-2">17.0</span>
                            <span class="media-body">
                                Contact Details
                            </span>
                        </button>
                    </li>
                </ul>
            </nav>
            <!-- End Nav -->
        </div>
        <div class="col-sm-8 col-lg-9">

            <p>
                Effective Date: 29th day of January, 2022
            </p>
            <!-- Info Content -->
            <div id="intro" class="space-2-bottom">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">1.0</span>
                    <h3>Introduction</h3>
                </div>

                <p>
                    These terms and conditions (the "Terms and Conditions") govern the use of
                    www.deatheducationassessmentdrills.com (the "Site"). This Site is owned and operated
                    by Anubis Publications Inc. This Site is an ecommerce website.
                </p>
                <p>
                    By using this Site, you indicate that you have read and understand these Terms and
                    Conditions and agree to abide by them at all times.
                </p>

            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="ip" class="space-2">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">2.0</span>
                    <h3>Intellectual Property</h3>
                </div>

                <p>
                    All content published and made available on our Site is the property of Anubis Publications Inc
                    and the Site's creators. This includes, but is not limited to images, text, logos, documents,
                    downloadable files and anything that contributes to the composition of our Site.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="age-restrictions" class="space-2">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">3.0</span>
                    <h3>Age Restrictions</h3>
                </div>

                <p>
                    The minimum age to use our Site is 18 years old. By using this Site,
                    users agree that they are over 18 years old. We do not assume any legal responsibility
                    for false statements about age.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="accounts" class="space-2">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">4.0</span>
                    <h3>Accounts</h3>
                </div>

                <p>
                    When you create an account on our Site, you agree to the following:
                </p>
                <ul>
                    <li>
                        You are solely responsible for your account and the security and privacy of your account,
                        including
                        passwords or sensitive information attached to that account
                    </li>
                    <li>
                        All personal information you provide to us through your account is up to date, accurate, and
                        truthful
                        and that you will update your personal information if it changes.
                    </li>
                </ul>
                <p>
                    We reserve the right to suspend or terminate your account if you are using our Site illegally or if
                    you violate these Terms and Conditions.
                </p>

            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="goods" class="space-2">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">5.0</span>
                    <h3>Sale of Goods And Services</h3>
                </div>

                <p>
                    These Terms and Conditions govern the sale of goods and services available on our Site.
                    The following goods are available on our Site:
                </p>
                <ul>
                    <li>
                        Education tools and services for funeral services businesses.
                    </li>
                </ul>
                <p>
                    The following services are available on our Site:
                </p>
                <ul>
                    <li>
                        Tutorial Services for Funeral Business Licensing Education.
                    </li>
                </ul>
                <p>
                    The services will be paid for in full when the services are ordered.
                </p>
                <p>
                    These Terms and Conditions apply to all the goods and services that are displayed on our Site at the
                    time you access it. This includes all products listed as being out of stock. All information,
                    descriptions, or images that we provide about our goods and services are as accurate as possible.
                    However, we are not legally bound by such information, descriptions, or images as we cannot
                    guarantee
                    the accuracy of all goods and services we provide. You agree to purchase goods and services from our
                    Site at your own risk.
                </p>
                <p>
                    We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we
                    cancel
                    your order and have already processed your payment, we will give you a refund equal to the amount
                    you
                    paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt
                    of
                    any refund.
                </p>

            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="third-goods" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">6.0</span>
                    <h3>Third Party Goods and Services</h3>
                </div>

                <p>
                    Our Site may offer goods and services from third parties.
                    We cannot guarantee the quality or accuracy of goods and services made available
                    by third parties on our Site.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="subscriptions" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">7.0</span>
                    <h3>Subscriptions</h3>
                </div>

                <p>
                    Your subscription does not automatically renew.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="payments" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">8.0</span>
                    <h3>Payments</h3>
                </div>

                <p>
                    We accept the following payment methods on our Site:
                </p>
                <ul>
                    <li>
                        Credit Card
                    </li>
                    <li>
                        Debit
                    </li>
                </ul>
                <p>
                    When you provide us with your payment information, you authorize our use of and access to the
                    payment instrument you have chosen to use. By providing us with your payment information, you
                    authorize us to charge the amount due to this payment instrument.
                </p>
                <p>
                    If we believe your payment has violated any law or these Terms and Conditions, we reserve the right
                    to
                    cancel or reverse your transaction.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="shipping" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">8.0</span>
                    <h3>Shipping and Delivery</h3>
                </div>
                <p>
                    When you purchase goods from our Site, the goods will be delivered through one of the following
                    methods:
                </p>
                <ul>
                    <li>
                        Standard Delivery in USA 5 - 7 business days.
                    </li>
                </ul>
                <p>
                    Delivery will take place as soon as reasonably possible, depending on the delivery method selected.
                    Delivery times may vary due to unforeseen circumstances. Please note that delivery times do not
                    include
                    weekends and statutory holidays.
                </p>
                <p>
                    You will be required to pay delivery charges in addition to the price for the goods you purchase.
                </p>
                <p>
                    If you purchase goods from us for delivery to a destination outside the United States your purchase
                    may
                    be subject to import duties and taxes applied by the destination country. You are responsible for
                    paying
                    any such duties or taxes. Please contact your local customs office for more information before
                    making a
                    purchase. We are not responsible for the payment of any such duties or taxes and are not liable for
                    any
                    failure by you to pay them.
                </p>
                <p>
                    You are required to provide us with a complete and accurate delivery address, including the name of
                    the
                    recipient. We are not liable for the delivery of your goods to the wrong address or wrong person as
                    a
                    result of you providing us with inaccurate or incomplete information.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="consumer-protection-law" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">10.0</span>
                    <h3>Consumer Protection Law</h3>
                </div>
                <p>
                    Where any consumer protection legislation in your jurisdiction applies and cannot be excluded, these
                    Terms and Conditions will not limit your legal rights and remedies under that legislation. These
                    Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there
                    is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of
                    the legislation will apply.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="links" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">11.0</span>
                    <h3>Links to Other Websites</h3>
                </div>
                <p>
                    Our Site contains links to third party websites or services that we do not own or control. We are
                    not responsible for the content, policies, or practices of any third party website or service linked
                    to on our Site. It is your responsibility to read the terms and conditions and privacy policies of
                    these third party websites before using these sites.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="liability" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">12.0</span>
                    <h3>Limitation of Liability</h3>
                </div>
                <p>
                    Our Site contains links to third party websites or services that we do not own or control. We are
                    not responsible for the content, policies, or practices of any third party website or service linked
                    to on our Site. It is your responsibility to read the terms and conditions and privacy policies of
                    these third party websites before using these sites.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="indemnity" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">13.0</span>
                    <h3>Indemnity</h3>
                </div>
                <p>
                    Except where prohibited by law, by using this Site you indemnify and hold harmless Anubis
                    Publications Inc and our directors, officers, agents, employees, subsidiaries, and affiliates from
                    any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of
                    your use of our Site or your violation of these Terms and Conditions.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="applicable-law" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">14.0</span>
                    <h3>Applicable Law</h3>
                </div>
                <p>
                    These Terms and Conditions are governed by the laws of the State of New York.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="severability" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">15.0</span>
                    <h3>Severability</h3>
                </div>
                <p>
                    If at any time any of the provisions set forth in these Terms and Conditions are found to be
                    inconsistent or invalid under applicable laws, those provisions will be deemed void and will be
                    removed from these Terms and Conditions. All other provisions will not be affected by the removal
                    and the rest of these Terms and Conditions will still be considered valid.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="changes" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">16.0</span>
                    <h3>Changes</h3>
                </div>
                <p>
                    These Terms and Conditions may be amended from time to time in order to maintain compliance with the
                    law and to reflect any changes to the way we operate our Site and the way we expect users to behave
                    on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice
                    on our Site.
                </p>
            </div>
            <!-- End Info Content -->

            <hr class="my-0">

            <!-- Info Content -->
            <div id="contact-details" class="space-2-top">
                <div class="mb-3">
                    <span class="d-block text-secondary font-weight-medium">17.0</span>
                    <h3>Contact Details</h3>
                </div>
                <p>
                    Please contact us if you have any questions or concerns. Our contact details are as follows:
                </p>
                <ul>
                    <li>
                        <a href="tel:(585) 356-4929">(585) 356-4929</a>
                    </li>
                    <li>
                        <a href="mailto:info@deatheducationassessmentdrills.com">
                            info@deatheducationassessmentdrills.com
                        </a>
                    </li>
                    <li>
                        3 Harrison Street, Norwood NY 13668
                    </li>
                </ul>
                <p>
                    You can also contact us through the feedback form available on our Site.
                </p>
            </div>
            <!-- End Info Content -->

        </div>
    </div>
</div>
<!-- Sticky Block End Point -->
<div id="stickyBlockEndPoint"></div>
