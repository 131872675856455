import { createAction, props } from '@ngrx/store';

export const logoutStarted = createAction(
    '[Msal] Logout Started',
    props<{ redirectUrl: string }>()
);

export const logoutSuccess = createAction(
    '[Msal] Logout Success',
    props<{ resp: any }>()
);

export const logoutFailure = createAction(
    '[Msal] Logout Failure',
    props<{ error: any }>()
);
