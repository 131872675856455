import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dead-yes-no-modal',
    templateUrl: './yes-no-modal.component.html',
    styleUrls: ['./yes-no-modal.component.scss']
})
export class YesNoModalComponent {
    @Input() header: string;
    @Input() body: string;
    @Input() continueBtnTxt: string;
    @Input() cancelBtnTxt = '';

    constructor(public activeModal: NgbActiveModal) { }

}
