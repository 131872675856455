import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { iif, Observable, of } from 'rxjs';
import { Question } from '../models/question.model';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { isNull, isUndefined } from 'lodash';
import { HttpUtilService } from './../../shared/http-util.service';
import { DbService } from './db.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionService {
    questionApiBase = 'question/';
    private tableName = 'questions';

    constructor(
        private http: HttpClient,
        private httpUtils: HttpUtilService,
        private dbService: DbService) { }

    deleteCache(): Observable<void> {
        return this.dbService.clearTableData<Question>(this.tableName);
    }

    get(questionId: string): Observable<Question> {
        return this.dbService.getFilter<Question>(
            this.tableName, 'id', questionId, `${environment.ADMIN_API_BASE}${this.questionApiBase}get/${questionId}`);
    }

    getAll(showDeleted: boolean = false): Observable<Question[]> {
        let url = `${environment.ADMIN_API_BASE}${this.questionApiBase}GetAll`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.dbService.getAll<Question>(this.tableName, url);
    }

    getStudentQuestionCollection(questionIds: string[], showAnswers: boolean = false): Observable<Question[]> {
        let httpParams = new HttpParams();
        questionIds.forEach(id => {
            httpParams = httpParams.append('questionIds', id);
        });
        let url = `${environment.STUDENT_API_BASE}${this.questionApiBase}get/collection`;
        if (showAnswers) {
            url += `/${showAnswers}`;
        }
        return this.http
            .get(
                url,
                { params: httpParams }
            )
            .pipe(map((p: Question[]) => p));
    }

    getStudentQuestionCollectionByTestRunId(testRunId: string, showAnswers: boolean = false): Observable<Question[]> {
        let url = `${environment.STUDENT_API_BASE}${this.questionApiBase}get/collection/testrun/${testRunId}`;
        if (showAnswers) {
            url += `/${showAnswers}`;
        }
        return this.http
            .get(url)
            .pipe(map((p: Question[]) => p));
    }

    getInstructorStudentQuestionCollection(questionIds: string[], showAnswers: boolean = false): Observable<Question[]> {
        let httpParams = new HttpParams();
        questionIds.forEach(id => {
            httpParams = httpParams.append('questionIds', id);
        });
        let url = `${environment.INSTRUCTOR_API_BASE}${this.questionApiBase}get/collection`;
        if (showAnswers) {
            url += `/${showAnswers}`;
        }
        return this.http
            .get(
                url,
                { params: httpParams }
            )
            .pipe(map((p: Question[]) => p));
    }

    codeExists(code: string, id: string = ''): Observable<boolean> {
        let url = `${environment.ADMIN_API_BASE}${this.questionApiBase}CodeExists/${code}`;
        if (id) {
            url += `/${id}`;
        }

        return this.dbService.getFilter<Question>(this.tableName, 'code', code)
            .pipe(
                mergeMap(p =>
                    iif(() =>
                        isUndefined(p) || isNull(p) || p.id !== id,
                        of(false),
                        this.http.get(url).pipe(map((i: boolean) => i))
                    ))
            );
    }

    add(newQuestion: Question): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.questionApiBase}Add`,
            newQuestion
        );
    }

    edit(editQuestion: Question): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.questionApiBase}Edit`,
            editQuestion
        );
    }

    delete(questionId: string): Observable<any> {
        return this.http.delete(
            `${environment.ADMIN_API_BASE}${this.questionApiBase}Delete/${questionId}`
        );
    }

    deleteCollection(questionIds: string[]): Observable<any> {
        return this.httpUtils.bulkDelete(questionIds, 'questionIds', `${environment.ADMIN_API_BASE}${this.questionApiBase}DeleteCollection`);
    }
}
