import { Component } from '@angular/core';

@Component({
  selector: 'dead-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {

  constructor() { }

}
