<!-- Skippy -->
<a id="skippy" class="sr-only sr-only-focusable u-skippy" href="#content">
  <div class="container">
    <span class="u-skiplink-text">Skip to main content</span>
  </div>
</a>
<!-- End Skippy -->
<div class="vh-100 d-flex flex-column">
  <dead-header *ngIf="showHeaderFooter && !isGameRoute"></dead-header>
  <main id="content" role="main" class="flex-fill">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </main>
  <dead-footer *ngIf="showHeaderFooter && !isGameRoute"></dead-footer>
</div>

<ng-http-loader
  [spinner]="spinkit.skChasingDots"
  [filteredHeaders]="['skipSpinner']"
></ng-http-loader>
