<!-- Privacy Section -->
<div class="bg-gray-100">
    <div class="container space-2 space-2-top--lg space-3-bottom--lg">
        <div class="w-lg-80 bg-white border rounded mx-lg-auto p-5 p-sm-7">
            <!-- Hero -->
            <div class="mb-9">
                <h1>Privacy Policy</h1>
                <p>
                    Last modified: January 29, 2022
                    <!-- (<a href="#">view archived versions</a>) -->
                </p>
            </div>
            <!-- End Hero -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h2 class="h4">Introduction</h2>
                </div>
                <p>
                    Thanks for using www.deatheducationassessmentdrills.com (the "Site") is owned and operated by Anubis
                    Publications Inc. <br />
                    Anubis Publications Inc. can be contacted at:
                </p>

                <li>
                    <a href="mailto:info@deatheducationassessmentdrills.com">
                        info@deatheducationassessmentdrills.com
                    </a>
                </li>
                <li><a href="tel:(585) 356-4929">(585) 356-4929</a></li>
                <li>3 Harrison Street, Norwood NY 13668</li>
                <br />
                <p>
                    The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the
                    following:
                </p>
                <ul>
                    <li>The personal data we will collect</li>
                    <li>Use of collected data</li>
                    <li>Who has access to the data collected</li>
                    <li>The rights of Site users</li>
                    <li>The Site's cookie policy</li>
                </ul>
                <p>
                    This Privacy Policy applies in addition to the terms and conditions of our Site.
                </p>
                <p>By using our Site users agree that they consent to:</p>

                <ul>
                    <li>The conditions set out in this Privacy Policy</li>
                    <li>The collection, use, and retention of the data listed in this Privacy Policy</li>
                </ul>
                <p>
                    We only collect data that helps us achieve the purpose set out in this Privacy Policy.
                    We will not collect any additional data beyond the data listed below without notifying you first.
                </p>
            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Personal Data We Collect</h3>
                </div>

                <p class="font-weight-bold">Data Collected Automatically</p>

                <p>
                    We only collect data that helps us achieve the purpose set out in this Privacy Policy.
                    We will not collect any additional data beyond the data listed below without notifying you first.
                </p>
                <p>
                    When you visit and use our Site, we may automatically collect and store the following information:
                </p>
                <ul>
                    <li>IP address</li>
                    <li>Location</li>
                    <li>Hardware and software details</li>
                    <li>Clicked links</li>
                    <li>Content viewed</li>
                </ul>

                <p class="font-weight-bold">Data Collected in a Non-Automatic Way</p>
                <p>We may also collect the following data when you perform certain functions on our Site</p>
                <ul>
                    <li>First and last name</li>
                    <li>Age</li>
                    <li>Date of birth</li>
                    <li>Sex</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Address</li>
                    <li>Payment information</li>
                </ul>

                <p>This data may be collected using the following methods</p>

                <ul>
                    <li>Account Creation</li>
                </ul>

            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">How We Use Personal Data</h3>
                </div>

                <p>
                    Data collected on our Site will only be used for the purposes specified in this Privacy Policy or
                    indicated on the relevant pages of our Site. We will not use your data beyond what we disclose in
                    this Privacy Policy.
                </p>
                <p>The data we collect automatically is used for the following purposes</p>
                <ul>
                    <li>Website Statistics and Analysis</li>
                </ul>
                <p>
                    The data we collect when the user performs certain functions may be used for the following purposes
                </p>
                <ul>
                    <li>File to file communications</li>
                </ul>
            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Who We Share Personal Data With</h3>
                </div>
                <p class="font-wieght-bold">Employees</p>
                <p>
                    We may disclose user data to any member of our organization who reasonably needs access
                    to user data to achieve the purposes set out in this Privacy Policy.
                </p>
                <p class="font-wieght-bold">Other Disclosures</p>
                <p>We will not sell or share your data with other third parties, except in the following cases</p>
                <ul>
                    <li>If the law requires it</li>
                    <li>If it is required for any legal proceeding</li>
                    <li>To prove or protect our legal rights</li>
                    <li>To buyers or potential buyers of this company in the event that we seek to sell the company</li>
                </ul>
                <p>
                    If you follow hyperlinks from our Site to another Site, please note that we are not responsible for
                    and have no control over their privacy policies and practices.
                </p>

            </div>
            <!-- End Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">How Long We Store Personal Data</h3>
                </div>
                <p>
                    User data will be stored until the purpose the data was collected for has been achieved. <br />
                    You will be notified if your data is kept for longer than this period.
                </p>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">How We Protect Your Personal Data</h3>
                </div>
                <p>
                    Best available browser encryption and data stored at secure facilities.
                    Only our employees can access the data and each employee is bound by very strict confidentiality
                    agreements
                </p>
                <p>
                    While we take all reasonable precautions to ensure that user data is secure and that users are
                    protected,
                    there always remains the risk of harm. The Internet as a whole can be insecure at times and
                    therefore we are
                    unable to guarantee the security of user data beyond what is reasonably practical.
                </p>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Children</h3>
                </div>
                <p>
                    The minimum age to use our website is 18 years of age.
                    We do not knowingly collect or use personal data from children under 13 years of age.
                    If we learn that we have collected personal data from a child under 13 years of age,
                    the personal data will be deleted as soon as possible. If a child under 13 years of
                    age has provided us with personal data their parent or guardian may contact our privacy officer.
                </p>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">How to Access, Modify, Delete, or Challenge the Data Collected</h3>
                </div>
                <p>
                    If you would like to know if we have collected your personal data, how we have used your personal
                    data,
                    if we have disclosed your personal data and to who we disclosed your personal data,
                    or if you would like your data to be deleted or modified in any way,
                    please contact our privacy officer here:
                </p>
                <ul>
                    <li>Steven L Johnson</li>
                    <li>
                        <a href="mailto:info@deatheducationassessmentdrills.com">
                            info@deatheducationassessmentdrills.com
                        </a>
                    </li>
                    <li><a href="tel:(585) 356-4929">(585) 356-4929</a></li>
                    <li>3 Harrison Street, Norwood NY 13668</li>
                </ul>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Do Not Track Notice</h3>
                </div>
                <p>
                    Do Not Track (“DNT”) is a privacy preference that you can set in certain web browsers.
                    We do not track the users of our Site over time and across third party websites and
                    therefore do not respond to browser-initiated DNT signals.
                </p>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Cookie Policy</h3>
                </div>
                <p>
                    A cookie is a small file, stored on a user’s hard drive by a website.
                    Its purpose is to collect data relating to the user's browsing habits.
                    You can choose to be notified each time a cookie is transmitted.
                    You can also choose to disable cookies entirely in your internet browser,
                    but this may decrease the quality of your user experience.
                </p>
                <p>
                    We use the following types of cookies on our Site:
                </p>
                <ul>
                    <li>
                        <span class="font-weight-bold">Functional cookies</span><br />
                        Functional cookies are used to remember the selections you make on our
                        Site so that your selections are saved for your next visits
                    </li>
                    <li>
                        <span class="font-weight-bold">Analytical cookies</span><br />
                        Analytical cookies allow us to improve the design and functionality of our
                        Site by collecting data on how you access our Site, for example data on the content you access,
                        how long you stay on our Site, etc
                    </li>
                    <li>
                        <span class="font-weight-bold">Targeting cookies</span><br />
                        Targeting cookies collect data on how you use the Site and your preferences.
                        This allows us to personalize the information you see on our Site for you
                    </li>
                    <li>
                        <span class="font-weight-bold">Third-Party Cookies</span><br />
                        Third-party cookies are created by a website other than ours.
                        We may use third-party cookies to achieve the following purposes: <br />
                        - User preferences to identify appropriate advertisements.
                    </li>
                </ul>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Modifications</h3>
                </div>
                <p>
                    This Privacy Policy may be amended from time to time in order to maintain compliance
                    with the law and to reflect any changes to our data collection process.
                    When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy
                    Policy.
                    We recommend that our users periodically review our Privacy Policy to ensure that
                    they are notified of any updates. If necessary, we may notify users by email of changes to this
                    Privacy Policy.
                </p>
            </div>

            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Contact Information</h3>
                </div>
                <p>
                    If you have any questions, concerns or complaints, you can contact our privacy officer, Steven L
                    Johnson, at:
                </p>

                <ul>
                    <li>
                        <a href="mailto:info@deatheducationassessmentdrills.com">
                            info@deatheducationassessmentdrills.com
                        </a>
                    </li>
                    <li><a href="tel:(585) 356-4929">(585) 356-4929</a></li>
                    <li>3 Harrison Street, Norwood NY 13668</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Privacy Section -->
