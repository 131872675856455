import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { concat, from, interval, of } from 'rxjs';
import { catchError, exhaustMap, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
    appUpdatesActive,
    appUpdatesAvailable,
    checkForAppUpdates,
    checkIfUserIsLoggedIn,
    clearQuestionCategories,
    initializeAppStart,
    serviceWorkDisabled,
    startAppUpdatesActive,
    startAppUpdatesAvailable,
    startMonitorOfMsalRedirect,
    userIsNotLoggedIn,
} from '../actions/app.actions';
import { loadCurrentUser } from '../actions/user.actions';
import { B2CPolicyFlows } from '../core/constants';
import { QuestionCategoryService } from '../core/services/question-category.service';
import { loginFailure, loginResponseRecieved, setUserState } from '../msal/actions/msal-login.actions';
import { logoutStarted } from '../msal/actions/msal-logout.actions';
import { passwordResetSuccess } from '../msal/actions/msal-password-reset.actions';
import { timeoutStartWatching } from '../msal/actions/timeout.actions';
import { DEADMsalService } from '../msal/services/dead-msal.service';
import { YesNoModalComponent } from '../shared/yes-no-modal/yes-no-modal.component';



@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private updates: SwUpdate,
        private modalService: NgbModal,
        private appRef: ApplicationRef,
        private deadMsalService: DEADMsalService,
        private msalService: MsalService,
        private toastService: ToastrService,
        private questionCategoriesService: QuestionCategoryService,
    ) {
    }

    initializeAppStart$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initializeAppStart),
                exhaustMap(() => {
                    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
                    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
                    const everySixHours$ = interval(6 * 60 * 60 * 1000);
                    return this.updates.isEnabled ?
                        concat(appIsStable$, everySixHours$)
                            .pipe(switchMap(() =>
                                [
                                    checkForAppUpdates(),
                                    startAppUpdatesAvailable(),
                                    startAppUpdatesActive(),
                                    startMonitorOfMsalRedirect(),
                                    checkIfUserIsLoggedIn()
                                ])) :
                        of(serviceWorkDisabled(), startMonitorOfMsalRedirect(), checkIfUserIsLoggedIn());
                })
            )
    );

    checkForAppUpdates$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(checkForAppUpdates),
                exhaustMap(() => from(this.updates.checkForUpdate()))
            ),
        {
            dispatch: false
        }
    );

    startAppUpdatesAvailable$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(startAppUpdatesAvailable),
                exhaustMap(() =>
                    this.updates.available.pipe(map(updateAvailableEvent => appUpdatesAvailable({ updateAvailableEvent })))
                )
            )
    );

    startAppUpdatesActive$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(startAppUpdatesActive),
                exhaustMap(() =>
                    this.updates.activated.pipe(map(updateActivatedEvent => appUpdatesActive({ updateActivatedEvent })))
                )
            )
    );

    appUpdatesAvailable$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(appUpdatesAvailable),
                exhaustMap(() => {
                    const modalRef = this.modalService.open(YesNoModalComponent, {
                        size: 'sm'
                    });
                    modalRef.componentInstance.header = 'App Update available';
                    modalRef.componentInstance.body = 'Choose Ok to update';
                    modalRef.componentInstance.continueBtnTxt = 'Ok';
                    return from(modalRef.result)
                        .pipe(
                            map(() => from(this.updates.activateUpdate())),
                            catchError(() => from(this.updates.activateUpdate())),
                            map(() => document.location.reload())
                        );
                })
            ),
        {
            dispatch: false
        }
    );

    startMonitorOfMsalRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(startMonitorOfMsalRedirect),
                exhaustMap(() =>
                    this.msalService.handleRedirectObservable()
                        .pipe(
                            filter((authResult: AuthenticationResult) => authResult !== null),
                            mergeMap((authResult: AuthenticationResult) => {
                                let claims: any = authResult?.idTokenClaims;
                                console.log('===============authResult', authResult)

                                // We need to reject id tokens that were not issued with the default sign-in policy.
                                // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
                                // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

                                if (claims?.acr === B2CPolicyFlows.B2C_PASSWORD_RESET_FLOW.toLowerCase()) {
                                    return [passwordResetSuccess({ data: authResult })];
                                } else if (claims?.acr === B2CPolicyFlows.B2C_PROFILE_EDIT_FLOW.toLowerCase()) {
                                    this.toastService.success('Please sign-in again.', 'Profile has been updated successfully!');
                                    //return this.msalService.logout();
                                }

                                return [loginResponseRecieved({ resp: authResult }), loadCurrentUser()];
                            }),
                            catchError(error => of(loginFailure({ error })))
                        )
                )
            )
    );

    checkIfUserIsLoggedIn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(checkIfUserIsLoggedIn),
                exhaustMap(() => {
                    // don't count password reset token as being logged in
                    if (this.deadMsalService.isLoggedIn() &&
                        this.deadMsalService.getClaims()?.acr !== B2CPolicyFlows.B2C_PASSWORD_RESET_FLOW.toLowerCase()) {
                        return of(
                            setUserState({
                                newState: {
                                    isLoggedIn: this.deadMsalService.isLoggedIn(),
                                    isAdmin: this.deadMsalService.isAdmin(),
                                    isInstructor: this.deadMsalService.isInstructor(),
                                    isStudent: this.deadMsalService.isStudent(),
                                    schoolId: this.deadMsalService.getSchoolId(),
                                    subExirationDate: this.deadMsalService.getSubExirationDate(),
                                    graduationDate: this.deadMsalService.getGrauationdDate(),
                                }
                            }),
                            loadCurrentUser(),
                            timeoutStartWatching()
                        );
                    }

                    return of(userIsNotLoggedIn());
                })
            )
    );

    clearQuestionCategories$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(clearQuestionCategories),
                exhaustMap(() => this.questionCategoriesService.deleteCache())
            ),
        {
            dispatch: false
        }
    );

}
