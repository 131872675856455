import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';
import { State } from './app.reducers';

/**
 * Router Selectors
 */
export const selectRouter = createFeatureSelector< fromRouter.RouterReducerState>('router');

export const { selectRouteData, selectUrl } = fromRouter.getRouterSelectors(selectRouter);
