import { createAction, props } from '@ngrx/store';
import { School } from '../core/models/school.model';

export const loadSchools = createAction('[School] Load Schools');

export const reloadSchools = createAction('[School] Reload Schools');

export const loadSchoolsSuccess = createAction(
    '[School] Load Schools Success',
    props<{ schools: School[] }>()
);

export const loadSchoolsFailure = createAction(
    '[School] Load Schools Failure',
    props<{ error: any }>()
);

export const addSchool = createAction(
    '[School] Add School',
    props<{ school: School, redirectUrl: string }>()
);

export const editSchool = createAction(
    '[School] Edit School',
    props<{ school: School, redirectUrl: string }>()
);

export const deleteSchool = createAction(
    '[School] Delete School',
    props<{ schoolId: string }>()
);

export const addEditDeleteSchoolComplete = createAction(
    '[School] Add/Edit/Delete School Complete',
    props<{ redirectUrl: string }>()
);

export const addEditDeleteSchoolFailure = createAction(
    '[School] Add/Edit/Delete School Failure',
    props<{ error: any }>()
);
