import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iif, Observable, of } from 'rxjs';
import { QuestionCategory } from '../models/questionCategory.model';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { isUndefined, isNull } from 'lodash';
import { DbService } from './db.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionCategoryService {
    questionCategoryApiBase = 'questionCategory/';
    private tableName = 'questionCategories';

    constructor(private http: HttpClient, private dbService: DbService) { }

    deleteCache(): Observable<void> {
        return this.dbService.clearTableData<QuestionCategory>(this.tableName);
    }

    get(id: string): Observable<QuestionCategory> {
        return this.dbService.getFilter<QuestionCategory>(
            this.tableName, 'id', id, `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}Get/${id}`);
    }

    getAll(showDeleted: boolean = false): Observable<QuestionCategory[]> {
        let url = `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}GetAll`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.http.get<QuestionCategory[]>(url);
    }

    getAllInstructor(showNumQuestions: boolean = false): Observable<QuestionCategory[]> {
        let url = `${environment.INSTRUCTOR_API_BASE}${this.questionCategoryApiBase}GetAll`;
        if (showNumQuestions) {
            url += `/${showNumQuestions}`;
        }

        return this.http.get<QuestionCategory[]>(url);
    }

    getAllStudent(showNumQuestions: boolean = false): Observable<QuestionCategory[]> {
        let url = `${environment.STUDENT_API_BASE}${this.questionCategoryApiBase}GetAll`;
        if (showNumQuestions) {
            url += `/${showNumQuestions}`;
        }

        return this.http.get<QuestionCategory[]>(url);
    }

    codeExists(code: string, id: string = ''): Observable<boolean> {
        let url = `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}CodeExists/${code}`;
        if (id) {
            url += `/${id}`;
        }

        return this.dbService.getFilter<QuestionCategory>(this.tableName, 'code', code)
            .pipe(
                mergeMap(p =>
                    iif(() =>
                        isUndefined(p) || isNull(p) || p.id !== id,
                        of(false),
                        this.http.get(url).pipe(map((i: boolean) => i))
                    ))
            );
    }

    add(newQuestionCategory: QuestionCategory): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}Add`,
            newQuestionCategory
        );
    }

    edit(editQuestionCategory: QuestionCategory): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}Edit`,
            editQuestionCategory
        );
    }

    delete(questionCategoryId: string): Observable<any> {
        return this.http.delete(
            `${environment.ADMIN_API_BASE}${this.questionCategoryApiBase}Delete/${questionCategoryId}`
        );
    }
}
