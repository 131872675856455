import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MsalState } from '../msal/reducers/msal.reducers';
import { loginStarted } from '../msal/actions/msal-login.actions';
import { signupStarted } from '../msal/actions/msal-signup.actions';

@Component({
    selector: 'dead-sign-in-sign-up',
    templateUrl: './sign-in-sign-up.component.html',
})
export class SignInSignUpComponent implements OnInit {
    redirectUrl: string;
    isActivatingSub = false;
    isPurchase = false;

    constructor(
        private msalStore: Store<MsalState>,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.activatedRoute.queryParams
            .subscribe(m => {
                this.redirectUrl = m[`redirect`];
            });
        this.isActivatingSub = this.redirectUrl.includes("activate");
        this.isPurchase = this.redirectUrl.includes("purchase");
    }

    login() {
        this.msalStore.dispatch(loginStarted({ redirectUrl: this.redirectUrl }));
    }

    signup() {
        this.msalStore.dispatch(signupStarted({ redirectUrl: this.redirectUrl }));
    }
}
