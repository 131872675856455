import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscriptions } from './subscriptions.constants';

@Component({
    selector: 'dead-subscription-types',
    templateUrl: './subscription-types.component.html',
    styleUrls: ['./subscription-types.component.scss']
})
export class SubscriptionTypesComponent implements OnInit {
    subscriptionTypes = [];
    constructor(private router: Router) { }

    purchase(code) {
        this.router.navigate([`/subscriptions/purchase/${code.toLowerCase()}`])
    }
    ngOnInit() {
        this.subscriptionTypes = Subscriptions.filter(p => p.isVisible);
    }
}
