import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { School } from '../models/school.model';
import { mergeMap } from 'rxjs/operators';
import { DbService } from './db.service';

@Injectable({
    providedIn: 'root'
})
export class SchoolService {
    schoolApiBase = 'school/';
    private tableName = 'schools';

    constructor(private http: HttpClient, private dbService: DbService) { }

    deleteCache(): Observable<void> {
        return this.dbService.clearTableData<School>(this.tableName);
    }

    get(id: string): Observable<School> {
        return this.dbService.getFilter<School>(this.tableName, 'id', id, `${environment.GENERAL_API_BASE}${this.schoolApiBase}get/${id}`);
    }

    getForInstructor(id: string): Observable<School> {
        return this.dbService.getFilter<School>(this.tableName, 'id', id, `${environment.INSTRUCTOR_API_BASE}${this.schoolApiBase}get/${id}`);
    }

    getAll(showDeleted: boolean = false): Observable<School[]> {
        let url = `${environment.GENERAL_API_BASE}${this.schoolApiBase}getall`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.dbService.getAll<School>(this.tableName, url);
    }

    add(newSchool: School): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.schoolApiBase}add`,
            newSchool
        ).pipe(mergeMap(() => this.deleteCache()));
    }

    edit(editSchool: School): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.schoolApiBase}edit`,
            editSchool
        ).pipe(mergeMap(() => this.deleteCache()));
    }

    delete(schoolId: string): Observable<any> {
        return this.http.delete(
            `${environment.ADMIN_API_BASE}${this.schoolApiBase}delete/${schoolId}`
        ).pipe(mergeMap(() => this.deleteCache()));
    }
}
