import { Component } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'dead-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    faFacebookF = faFacebookF;
    todayYear = new Date().getFullYear();
    constructor() { }

}
