import { UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { createAction, props } from '@ngrx/store';

export const initializeAppStart = createAction('[App] Initialize App Start');

export const serviceWorkDisabled = createAction('[App] Service Worker Disabled');

export const checkForAppUpdates = createAction('[App] Check For App Updates');

export const startAppUpdatesAvailable = createAction('[App] Start App Updates Available');

export const startAppUpdatesActive = createAction('[App] Start App Updates Active');

export const appUpdatesAvailable = createAction(
    '[App] App Updates Available',
    props<{ updateAvailableEvent: UpdateAvailableEvent }>()
);

export const appUpdatesActive = createAction(
    '[App] App Updates Active',
    props<{ updateActivatedEvent: UpdateActivatedEvent }>()
);

export const startMonitorOfMsalRedirect = createAction('[App] Start Monitor Of Msal Redirect');

export const msalRedirectNoResponse = createAction('[App] Msal Redirect No Response');

export const checkIfUserIsLoggedIn = createAction('[App] Check If User Is Logged In');

export const userIsNotLoggedIn = createAction('[App] User Is Not Logged In');

export const clearQuestionCategories = createAction('[App] Clear Question Categories');
