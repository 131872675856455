import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../models/user.model';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DbService } from './db.service';
import { saveAs } from 'file-saver';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    public user;
    private userApiBase = 'users/';
    private tableName = 'users';

    constructor(
        private http: HttpClient,
        private dbService: DbService
    ) { }

    clearUserCache(): Observable<void> {
        return this.dbService.clearTableData<User>(this.tableName);
    }

    getBySchoolId(schoolId: string): Observable<User[]> {
        return this.dbService.getAll<User>(this.tableName, `${environment.INSTRUCTOR_API_BASE}${this.userApiBase}school/${schoolId}`);
    }

    getAll(): Observable<User[]> {
        return this.dbService.getAll<User>(this.tableName, `${environment.ADMIN_API_BASE}${this.userApiBase}GetAll`);
    }

    getCurrent(): Observable<User> {
        return this.http.get(`${environment.STUDENT_API_BASE}${this.userApiBase}Get/current`)
            .pipe(map((p: User) => p));
    }

    updateUserGroups(user: User): Observable<any> {
        return this.http.post(`${environment.ADMIN_API_BASE}${this.userApiBase}updateGroups`, user)
            .pipe(mergeMap(() => this.dbService.updateRecord<User>(this.tableName, user, user.userId)));
    }

    updateUserSubEndDate(user: User): Observable<any> {
        return this.http.post(`${environment.ADMIN_API_BASE}${this.userApiBase}subscription/update`, user)
            .pipe(mergeMap(() => this.dbService.updateRecord<User>(this.tableName, user, user.userId)));
    }

    updateUserGradDate(user: User): Observable<any> {
        return this.http.post(`${environment.GENERAL_API_BASE}${this.userApiBase}graduationDate/update`, user)
            .pipe(mergeMap(() => this.dbService.updateRecord<User>(this.tableName, user, user.userId)));
    }

    updateUserSchool(user: User): Observable<any> {
        return this.http.post(`${environment.ADMIN_API_BASE}${this.userApiBase}school/update`, user)
            .pipe(mergeMap(() => this.dbService.updateRecord<User>(this.tableName, user, user.userId)));
    }

    updateUserDetails(user: User): Observable<any> {
        return this.http.post(`${environment.GENERAL_API_BASE}${this.userApiBase}updateDetails`, user)
            .pipe(mergeMap(() => this.dbService.updateRecord<User>(this.tableName, user, user.userId)));
    }
    exportUsers(): Observable<boolean> {
        return this.http.get(`${environment.ADMIN_API_BASE}${this.userApiBase}export`,
            {
                responseType: 'blob',
                reportProgress: true
            })
            .pipe(switchMap((data) => {
                const blob = new Blob([data], { type: 'text/csv' });
                saveAs(blob, 'users.csv');
                return of(true);
            }));
    }
}
