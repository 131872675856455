import { createAction, props } from '@ngrx/store';
import { Faq } from '../core/models/faq.model';
import { FaqState } from '../reducers/faq.reducer';

export const loadFaqs = createAction('[FAQs] Load');

export const deleteFaqs = createAction('[FAQs] Delete',
props<{ids: string[]}>());
export const addFaq = createAction(
    '[FAQs] Add',
    props<{ faq: Faq }>()
);
export const editFaq = createAction(
    '[FAQs] Edit',
    props<{ faq: Faq }>()
);
export const updateFaqs = createAction(
    '[FAQs] Update',
    props<{ newState: FaqState }>()
);
