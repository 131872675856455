import { AuthenticationResult } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';

export const passwordResetStarted = createAction(
    '[Msal] Password Reset Started',
    props<{ redirectUrl: string }>()
);

export const passwordResetSuccess = createAction(
    '[Msal] Password Reset Success',
    props<{ data: AuthenticationResult }>()
);
