import { createAction } from '@ngrx/store';

export const timeoutStartWatching = createAction('[Timeout] Timeout Start Watching');

export const timeoutStartTimer = createAction('[Timeout] Start Timer');

export const timeoutStopTimer = createAction('[Timeout] Stop Timer');

export const timeoutOnTimeout = createAction('[Timeout] On Timeout');

