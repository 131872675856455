import { DeadSubscription } from '../../core/models/subscription.model'

export const SubscriptionTypes = {
    SEVEN_DAY_TRIAL: 'SEVEN_DAY_TRIAL',
    ONE_MONTH: 'ONE_MONTH',
    THREE_MONTHS: 'THREE_MONTHS',
    SIX_MONTHS: 'SIX_MONTHS',
    SIX_MONTHS_BULK: 'SIX_MONTHS_BULK',
    OTHER: 'OTHER',
    YEAR_BULK: 'YEAR_BULK',
    YEAR: 'YEAR',
    BULK_PURCHASE: 'BULK-PURCHASE'
}
export const Subscriptions: DeadSubscription[] = [
    {
        name: '7 Day Trial',
        code: SubscriptionTypes.SEVEN_DAY_TRIAL,
        description: '7 Day Trial description',
        price: 0.00,
        sortOrder: 100,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: false,
        isAdminVisible: true
    },
    {
        name: 'One Month',
        code: SubscriptionTypes.ONE_MONTH,
        description: 'One month description',
        price: 29.95,
        sortOrder: 110,
        isVisible: true,
        isDeleted: false,
        isInPurchaseHistory: true,
        isAdminVisible: true
    },
    {
        name: 'Three Months',
        code: SubscriptionTypes.THREE_MONTHS,
        description: 'Three month description',
        price: 49.95,
        sortOrder: 120,
        isVisible: true,
        isDeleted: false,
        isInPurchaseHistory: true,
        isAdminVisible: true
    },
    {
        name: 'Six Months',
        code: SubscriptionTypes.SIX_MONTHS,
        description: 'Six months description',
        price: 79.95,
        sortOrder: 130,
        isVisible: true,
        isDeleted: false,
        isInPurchaseHistory: true,
        isAdminVisible: true
    },
    {
        name: 'Six Months Bulk',
        code: SubscriptionTypes.SIX_MONTHS_BULK,
        description: 'Six months bulk description',
        price: 49.95,
        sortOrder: 140,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: true,
        isAdminVisible: false
    },
    {
        name: 'Other Name',
        code: SubscriptionTypes.OTHER,
        description: 'Other description',
        price: 0,
        sortOrder: 150,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: false,
        isAdminVisible: false
    },
    {
        name: 'One Year Bulk',
        code: SubscriptionTypes.YEAR_BULK,
        description: 'One Year Bulk',
        price: 99.90,
        sortOrder: 160,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: false,
        isAdminVisible: false
    },
    {
        name: 'One Year',
        code: SubscriptionTypes.YEAR,
        description: 'One Year description',
        price: 99.90,
        sortOrder: 170,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: false,
        isAdminVisible: true
    },
    {
        name: 'Bulk purchase',
        code: SubscriptionTypes.BULK_PURCHASE,
        description: 'Bulk purchase',
        price: 49.95,
        sortOrder: 180,
        isVisible: false,
        isDeleted: false,
        isInPurchaseHistory: false,
        isAdminVisible: false
    }
]

export const FiscalQuarters =
{
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
    Q4: 'Q4'
}


