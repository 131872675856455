import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dead-route',
    templateUrl: './route.component.html',
})
export class RouteComponent  {

  constructor() { }

}
